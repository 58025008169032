import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { format } from "date-fns"

type UserChat = {
	id: number;
	query: string | null;
	response: ChatResponse;
	created_at: string;
	updated_at: string;
	chatbot_id: number;
	user_chat_list_id: number;
}

type ChatResponse = {
	id: number;
	name: string;
	option: string[];
	rating: number | null;
	source: string[];
	message: string;
	card_type: string;
	chatbot_id: number;
	field_name: string | null;
	video_data: Omit<VideoData, "video_url">;
	video_link: string | null;
	target_card: number | null;
	user_guides: UserGuide[];
	stars_rating: number | null;
	embeded_forms: any[];
	card_wait_time: number | null;
	previous_card_id: (number | null)[];
	criteria_routings: any[];
	maximum_selection: number | null;
	minimum_selection: number | null;
	connector_card_links: ConnectorCardLink[];
}

type VideoData = {
	title: string;
	description: string | null;
	thumbnail_url: string;
    video_url: string;
}

type UserGuide = {
	id: number;
	title: string;
	content: string;
}

type ConnectorCardLink = {
	id: number;
	link_url: string | null;
	icon_file: string | null;
	link_name: string;
	link_type: string;
	open_link: string;
	created_at: string;
	updated_at: string;
	destination: string;
	connector_card_id: number;
}


type UserChatListAttribute = {
    id: number;
    chat_name: string;
    chatbot_id: number;
    account_id: number;
    operating_system: string;
    chat_id: string;
    status: string;
    created_at: string;
    updated_at: string;
    chatbot_icon: string;
}
  
type UserChatList =  {
    id: string;
    type: string;
    attributes: UserChatListAttribute;
}

export type ChatMessage = {
    message: string;
    sender: "user" | "bot";
    time: string;
    video_data?: VideoData;
    connector_card_links?: ConnectorCardLink[];
    user_guides?: UserGuide[];
}

export interface ChatsProps {
    id: string;
    navigation: any;
}

interface S {
    chatStatus: "open" | "closed";
    exportAnchorEl: HTMLButtonElement |null;
    token: string;
    userChatLists: UserChatListAttribute[];
    userChatListsLoading: boolean;
    selectedChat: UserChatListAttribute | null;
    selectedChatLoading: boolean;
    chatMessages: ChatMessage[];
    currentPage: number;
    totalUserChatLists: number;
}

interface SS {}

export default class ChatsController extends BlockComponent<ChatsProps, S, SS> {
    
    chatsPageSize = 15;

    constructor(props: ChatsProps) {
        super(props)
        this.receive = this.receive.bind(this)

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            chatStatus: "open",
            exportAnchorEl: null,
            token: "",
            userChatLists: [],
            userChatListsLoading: true,
            selectedChat: null,
            selectedChatLoading: true,
            chatMessages: [],
            currentPage: 1,
            totalUserChatLists: 0,
        }

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
    }

    async receive(from: string, message: Message) {
        if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            this.handleRestApiResponse(message)
        }
    }

    handleRestApiResponse = (message: Message) => {
        const responseHandlers: Record<string, (newMsg: Message) => void> = {}
        const {title} = message.getData(getName(MessageEnum.NavigationPropsMessage))
        
        if(title === "ALL_USER_CHATS") {
            responseHandlers["ALL_USER_CHATS"] = this.handleFetchAllUserChatsResponse
        }

        if(title === "SELECTED_USER_CHAT_MESSAGES") {
            responseHandlers["SELECTED_USER_CHAT_MESSAGES"] = this.handleFetchSelectedUserChatMessageResponse
        }
    
        const handler = responseHandlers[title]
        if(handler) {
          handler(message)
        }
    }

    handleFetchAllUserChats = (token: string, page: number) => {
        const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const header = {
            "Content-Type": "application/json",
            token: token
        }
        reqMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        reqMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        reqMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_chatbot6/user_chat_lists?per_page=${this.chatsPageSize}&page=${page}&status=${this.state.chatStatus}`
        )
        reqMessage.addData(
            getName(MessageEnum.NavigationPropsMessage), 
            {title: "ALL_USER_CHATS"}
        )
        this.send(reqMessage)
    }

    handleFetchSelectedUserChatMessage = () => {
        const newReqMsg = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const header = {
            token: this.state.token,
            "Content-Type": "application/json"
        }
        newReqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        )
        newReqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        )
        newReqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_chatbot6/user_chat_lists/${this.state.selectedChat?.id}?chatbot_id=${this.state.selectedChat?.chatbot_id}`
        )
        newReqMsg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            {title: "SELECTED_USER_CHAT_MESSAGES"}
        )
        this.send(newReqMsg)
    }

    handleFetchAllUserChatsResponse = async(message: Message) => {
        const allUserChatsResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        if(!allUserChatsResponse) {
            toast.error("Failed to fetch all chats", {className: "error__toast"})
            return;
        }

        if(allUserChatsResponse.errors?.[0]?.token) {
            await this.handleLogout()
            return;
        }

        if(allUserChatsResponse.user_lists.data) {
            const userChatLists = allUserChatsResponse.user_lists.data as UserChatList[]
            const chatsAttributes = userChatLists.map(chat => chat.attributes)
            const newUserChatsLists = [...this.state.userChatLists, ...chatsAttributes]
            const {current_page, total_items} = allUserChatsResponse.meta

            if(newUserChatsLists.length === 0) {
                toast.error("No chats found!", {className: "error__toast"})
                this.setState({userChatListsLoading: false, selectedChatLoading: false})
                return;
            }

            this.setState({
                userChatLists: newUserChatsLists, 
                userChatListsLoading: false, 
                selectedChat: this.state.selectedChat ? this.state.selectedChat : newUserChatsLists[0],
                currentPage: current_page,
                totalUserChatLists: total_items
            }, () => this.handleFetchSelectedUserChatMessage())
        }
    }

    handleFetchSelectedUserChatMessageResponse = async(message: Message) => {
        const chatResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
        if(!chatResponse) {
            toast.error("Failed to fetch", {className: "error__toast"})
            return;
        }

        if(chatResponse.errors?.[0]?.token) {
            await this.handleLogout()
            return;
        }

        if(chatResponse.user_chat_list) {
            const userChats = chatResponse.user_chat_list.data.attributes.user_chats as UserChat[]
            const reducedUserChatMessages = this.reducedChatMessages(userChats)
            this.setState({selectedChatLoading: false, chatMessages: reducedUserChatMessages})
        }
    }

    async componentDidMount() {
        const token = await getStorageData("authToken") ?? sessionStorage.getItem("authToken")
        this.setState({token})
        this.handleFetchAllUserChats(token, this.state.currentPage)
    }

    handleLogout = async() => {
        await removeStorageData("authToken")
        await removeStorageData("userId")
        sessionStorage.clear()
    
        const navigationMesaage = new Message(getName(MessageEnum.NavigationSignupLoginMessage))
        navigationMesaage.addData(
          getName(MessageEnum.NavigationPropsMessage),
          {navigation: this.props.navigation}
        )
        this.send(navigationMesaage)
    }

    reducedChatMessages = (userChats: UserChat[]): ChatMessage[] => {
        return userChats.reduce((acc: ChatMessage[], chat) => {
            const { query, created_at, response } = chat;
            const { message, card_type, video_data, connector_card_links, user_guides, video_link } = response;
            
            const time = format(new Date(created_at), 'hh:mm a')
            // If there's a query, push it as a message from the user
            if (query) {
              acc.push({
                message: query,
                sender: 'user',
                time
              });
            }
        
            // Create the base reduced chat object with the bot's message and metadata
            const reducedChat: ChatMessage = {
              message,
              sender: 'bot',
              time
            };
        
            // Conditionally add fields based on card_type
            if (card_type === 'share_video' && video_data && video_link) {
              reducedChat.video_data = {
                ...video_data,
                video_url: video_link
              };
            }
        
            if (card_type === 'share_link' && connector_card_links.length > 0) {
              reducedChat.connector_card_links = connector_card_links;
            }
        
            if (card_type === 'share_user_guide' && user_guides.length > 0) {
              reducedChat.user_guides = user_guides;
            }
        
            // Add the reduced chat object to the accumulator array
            acc.push(reducedChat);

            return acc;
        }, []);
    };

    onToggleChatTab = (status: "open" | "closed") => () => {
        this.setState({
            chatStatus: status,
            userChatListsLoading: true,
            userChatLists: [],
            currentPage: 1,
            totalUserChatLists: 0,
            selectedChatLoading: true,
            selectedChat: null,
        }, () => {
            this.handleFetchAllUserChats(this.state.token, this.state.currentPage)
        })
    }

    onSelectChat = (chat: UserChatListAttribute) => () => {
        this.setState({
            selectedChat: chat,
            selectedChatLoading: true
        }, () => this.handleFetchSelectedUserChatMessage())
    }

    onShowExportPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({exportAnchorEl: event.currentTarget})
    }

    onCloseExportPopover = () => {
        this.setState({exportAnchorEl: null})
    }

    handleFetchMoreChatLists = () => {
        this.handleFetchAllUserChats(this.state.token, this.state.currentPage + 1)
    }

    onExportChatMessages = () => {
        if(this.state.chatMessages.length === 0 || this.state.userChatLists.length === 0 || !this.state.selectedChat) {
            return;
        }

        const {chat_id, chat_name, id, created_at, updated_at} = this.state.selectedChat

        const startTime = format(new Date(created_at), 'hh:mm a')
        const endTime = format(new Date(updated_at), 'hh:mm a')

        const data = [
            ['Conversation ID','Chatbot name','User ID','Timestamp (chat initiated)','Timestamp (chat closed)','Chat duration'],
            [chat_id,chat_name,id,created_at,updated_at,`${startTime} - ${endTime}`]
        ]

        let botMessageIndex = 1;
        let userMessageIndex = 1;
        this.state.chatMessages.forEach((chat) => {
            let columnName;

            if(chat.sender === "bot") {
                columnName = `Chatbot message ${botMessageIndex}`
                botMessageIndex++;
            } else {
                columnName = `User message ${userMessageIndex}`
                userMessageIndex++;
            }

            data[0].push(columnName)
            data[1].push(chat.message)
        })

        const csvContent = data.map(row => row.map(value => `"${value}"`).join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const linkTag = document.createElement('a');
        const url = URL.createObjectURL(blob);
        linkTag.setAttribute('href', url);
        linkTag.setAttribute('download', `${chat_name}-${chat_id}`);

        document.body.appendChild(linkTag);
        linkTag.click();
        document.body.removeChild(linkTag);
    }
}