import React from "react";

// Customizable Area Start
import { Box, styled, CircularProgress } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomAppHeader from "../../../components/src/CustomAppHeader.web";
import ModeChart from "./components/ModeChart.web";
import PatientFilterByDate from "./components/PatientFilterByDate.web";
import PatientStackBarChart from "./components/PatientStackBarChart.web";

const OverlaySpinnerContainer = styled("div")({
  position: "fixed",
  inset: 0,
  zIndex: 100,
  width: "100vw",
  height: "100dvh",
  display: "grid",
  placeItems: "center",
  backgroundColor: "#0F172A70",
  backdropFilter: "blur(4px)",
})

const VisualAnalyticsContainer = styled(Box)({
  backgroundColor: "#FAFAFA",
  display: "flex",
})

const AnalyticsChartsContainer = styled(Box)({
  margin: "14px 48px",
  display: "flex",
  flexDirection: "column",
  gap: 24,
  backgroundColor: "#FFFFFF",
  borderRadius: 8,
})

const ChartsSection = styled("section")({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  borderRadius: 8,
  backgroundColor: "#FFFFFF",
  padding: 24,
  boxShadow: "0px 2px 8px 0px #00000014",
  boxSizing: "border-box",
  '& .chart-section-header': {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "12px 24px",
    '&.items-center': {
      alignItems: "center",
    },
  },
  "&.chart-1": {
    flex: "1 0 502px",
    height: 241,
  },
  "&.chart-2": {
    flex: "1 0 300px",
    height: 241,
    justifyContent: "center",
    "& .value": {
      fontFamily: "Cairo",
      fontSize: 40,
      lineHeight: "31px",
      fontWeight: 700,
      color: "#64748B"
    },
  },
  "& .chart-heading": {
    margin: 0,
    fontFamily: "Cairo",
    fontSize: 16,
    lineHeight: "20px",
    fontWeight: 700,
    color: "#64748B"
  },
  '& .chart-subheading': {
    margin: 0,
    color: "#64748B",
    fontFamily: "Cairo",
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: 400,
  },
  '& .text-center': {
    textAlign: "center",
  },
  '& .labels': {
    display: "flex",
    gap: 8,
    flexDirection: "column",
    fontFamily: "Cairo",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "16px",
    color: "#0F172A",
    '& > h3': {
      margin: 0,
      color: "#6F96AA",
      fontSize: 14,
      lineHeight: "26px",
      fontWeight: 700,
    },
    '& > div': {
      display: "flex",
      gap: 8,
      alignItems: "center",
      '& > span:first-child': {
        display: "inline-block",
        width: 12,
        height: 12,
        borderRadius: 20,
      },
    },
  },
})

const questions: string[] = ["Appointments", "General Enquiries", "Manage/check referrals", "Prescriptions", "Sick/Fit Notes", "Test Results"]

const questionsColor: Record<string, string> = {
  "Appointments": "#314A84",
  "General Enquiries": "#FF606B",
  "Manage/check referrals": "#F59E0B",
  "Prescriptions": "#34D399",
  "Sick/Fit Notes": "#C1C7CD",
  "Test Results": "#7D6AEB"
}

// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {
          this.state.loading && (
            <OverlaySpinnerContainer>
              <CircularProgress style={{height: 60, width: 60, color: "#FFFFFF"}} />
            </OverlaySpinnerContainer>
          )
        }
        <VisualAnalyticsContainer>
          <Sidebar  activeMenu="analytics" navigation={this.props.navigation} />
          <Box sx={{width:"100%"}} >
            <CustomAppHeader title="Analytics" navigation={this.props.navigation} />
            <AnalyticsChartsContainer>
              <ChartsSection>
                <div className="chart-section-header items-center">
                  <h2 className="chart-heading">Patient Intent by practice (Per Month)</h2>
                  <PatientFilterByDate onDateRangeSelect={this.onDateRangeSelect} onLastYearSelect={this.onLastYearSelect} />
                </div>
                <div className="chart-section-header">
                  <PatientStackBarChart patientsData={this.state.patientsData} chartLabels={this.state.chartLabels} />
                  <div className="labels" >
                    <h3>Question</h3>
                    {
                      questions.map((question) => (
                        <div key={question} >
                          <span style={{backgroundColor: questionsColor[question]}} />
                          <span>{question}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </ChartsSection>
              <Box style={{display: "flex", gap: 24, flexWrap: "wrap"}} >
                  <ChartsSection className="chart-1">
                    <h2 className="chart-heading">Count of Chats by Mode</h2>
                    <ModeChart data-testid="mode-chart" mobilePercentage={this.state.mobilePercentage} desktopPercentage={this.state.desktopPercentage} />
                  </ChartsSection>
                  <ChartsSection className="chart-2">
                    <div>
                      <h2 className="chart-heading text-center">Total “Chats”</h2>
                      <p className="chart-subheading text-center" >(All time)</p>
                    </div>
                    <span className="value text-center">{this.formatNumber(this.state.totalChats)}</span>
                  </ChartsSection>
                  <ChartsSection className="chart-2">
                    <h2 className="chart-heading text-center">Most popular “Hour”</h2>
                    <span className="value text-center" >{this.state.mostPopularHour}</span>
                  </ChartsSection>
              </Box>
            </AnalyticsChartsContainer>
          </Box>
        </VisualAnalyticsContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
